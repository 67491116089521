import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, tap } from 'rxjs';

import { AuthService } from 'app/core/auth/auth.service';
import { Notificacao } from 'app/model/notificacao';

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  private _minhasNotificacoes: BehaviorSubject<Notificacao[]> = new BehaviorSubject([]);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _auth: AuthService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for notifications
   */
  get minhasNotificacoes$(): Observable<Notificacao[]> {
    return this._minhasNotificacoes.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  obterMinhasNotificacoes(): Observable<Notificacao[]> {
    return this._httpClient.get<Notificacao[]>(`${environment.api}/notificacao`).pipe(
      catchError((error) => {
        throw error;
      }),
      tap((notificacoes: Notificacao[]) => {
        this._minhasNotificacoes.next(notificacoes);
      })
    );
  }

  marcarComoLida(notificacao: Notificacao): Observable<void> {
    return this._httpClient.post<void>(`${environment.api}/notificacao/marcarComoLido`, notificacao).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }

  marcarTodasComoLida(): Observable<void> {
    return this._httpClient.get<void>(`${environment.api}/notificacao/marcarTodosComoLido`).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }
}
