import { Route } from '@angular/router';
import { CreateQrCodeResolver, InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { IframeCotacoesComponent } from './iframe-cotacoes/iframe-cotacoes.component';
import { IframeCotacoesResolver } from './iframe-cotacoes/iframe-cotacoes.resolver';
import { DominioGuard } from './core/auth/guards/Dominio.Guard';
import { VerificarAceiteTermoResolver } from './modules/landing/aceitar-termos/aceitar-termos.resolvers';
import { NotificationsResolver } from './layout/common/notifications/notifications.resolvers';
import { FormsComponent } from './forms/forms.component';
import { GraficoDinamicaComponent } from './modules/crm/gestao-campanha/campanha/gerenciador/list/modals/fluxo/grafico-dinamica/grafico-dinamica.component';
import { GraficoDinamicaResolver } from './modules/crm/gestao-campanha/campanha/gerenciador/list/modals/fluxo/grafico-dinamica/grafico-dinamica.resolver';
import { MinhasNotificacoesResolver } from './layout/common/notify/notify.resolvers';
import { CarteirinhaComponent } from './modules/gestao-sindical/carteirinha/carteirinha.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },

  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboards' },
  {
    path: 'iframe/:modo/:cota',
    component: IframeCotacoesComponent,
    resolve: { dados: IframeCotacoesResolver },
  },
  {
    path: 'grafico/:id',
    component: GraficoDinamicaComponent,
    resolve: { dados: GraficoDinamicaResolver },
  },
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'form',
        component: FormsComponent,
        loadChildren: () => import('app/forms/forms.module').then((m) => m.FormsCampanhaModule),
      },
      {
        path: 'gestao-sindical/associados-publico/carteirinha/:id',
        component: CarteirinhaComponent,
      },
    ],
  },

  //
  // ======================= ROTAS SEM GUARDA =======================================
  //

  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'compact',
    },
    children: [
      {
        path: 'home',
        canActivate: [DominioGuard],
        loadChildren: () => import('app/modules/landing/landing.module').then((m) => m.LandingModule),
      },
      {
        path: 'aceitar-termos',
        loadChildren: () => import('app/modules/landing/aceitar-termos/aceitar-termos.module').then((m) => m.LandingAceitarTermosModule),
      },
      {
        path: 'company',
        canActivate: [DominioGuard],
        loadChildren: () => import('app/modules/landing/company/company.module').then((m) => m.CompanyModule),
      },
      {
        path: 'form',
        canActivate: [DominioGuard],
        loadChildren: () => import('app/modules/form-empresa/form-empresa.module').then((m) => m.FormEmpresaModule),
      },
      {
        path: 'promotor',
        canActivate: [DominioGuard],
        loadChildren: () => import('app/modules/form-promotor/form-promotor.module').then((m) => m.FormPromotorModule),
      },
      // ======================= ROTAS FILHAS QUANDO NÃO LOGADO =======================================
      {
        path: 'confirmation-required',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        loadChildren: () =>
          import('app/modules/auth/confirmation-required/confirmation-required.module').then((m) => m.AuthConfirmationRequiredModule),
      },
      {
        path: 'forgot-password',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then((m) => m.AuthResetPasswordModule),
      },
      {
        path: 'sign-in',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule),
      },
      {
        path: 'sign-up',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then((m) => m.AuthSignUpModule),
      },
    ],
  },
  {
    component: LayoutComponent,
    path: '',
    children: [
      {
        path: 'tv',
        loadChildren: () => import('app/modules/tv/tv.module').then((m) => m.TvModule),
      },
      {
        path: 'sessao',
        loadChildren: () => import('app/modules/votacao/votacao.module').then((m) => m.VotacaoModule),
      },
    ],
    data: {
      layout: 'empty',
    },
  },
  {
    component: LayoutComponent,
    path: '',
    children: [
      {
        path: 'extra',
        loadChildren: () => import('app/modules/extra/extra.module').then((m) => m.ExtraModule),
      },
    ],
    data: {
      layout: 'empty',
    },
  },

  //
  // ======================= ROTAS COM GUARDA =======================================
  //

  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
      notificationsResolver: NotificationsResolver,
      verificarAceiteTermoResolver: VerificarAceiteTermoResolver,
      notificacoes: MinhasNotificacoesResolver,
    },
    children: [
      // Dashboards
      {
        path: 'dashboards',
        children: [
          {
            path: '',
            loadChildren: () => import('app/modules/dashboards/dashboards.module').then((m) => m.DashboardsModule),
          },
        ],
      },
      {
        path: 'crm',
        loadChildren: () => import('app/modules/crm/crm.module').then((m) => m.CrmModule),
      },
      {
        path: 'qrcode',
        resolve: {
          create: CreateQrCodeResolver,
        },
        children: [],
      },
      {
        path: 'financeiro',
        loadChildren: () => import('app/modules/financeiro/financeiro.module').then((m) => m.FinanceiroModule),
      },
      {
        path: 'csr',
        loadChildren: () => import('app/modules/csr/csr.module').then((m) => m.CsrModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('app/modules/admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'gestao-sindical',
        loadChildren: () => import('app/modules/gestao-sindical/gestao-sindical.module').then((m) => m.GestaoSindicalModule),
      },
      {
        path: 'faegJovem',
        loadChildren: () => import('app/modules/faeg-jovem/faeg-jovem.module').then((m) => m.FaegJovemModule),
      },
      {
        path: 'financeiro',
        loadChildren: () => import('app/modules/financeiro/financeiro.module').then((m) => m.FinanceiroModule),
      },
      {
        path: 'csr',
        loadChildren: () => import('app/modules/csr/csr.module').then((m) => m.CsrModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('app/modules/admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'gestao-sindical',
        loadChildren: () => import('app/modules/gestao-sindical/gestao-sindical.module').then((m) => m.GestaoSindicalModule),
      },

      // { path: 'chat', loadChildren: () => import('app/modules/chat/chat.module').then(m => m.ChatModule) },
      // { path: 'gestao-campanha', loadChildren: () => import('app/modules/gestao-campanha/gestao-campanha.module').then(m => m.GestaoCampanhaModule) },

      {
        path: 'profile',
        loadChildren: () => import('app/modules/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'calendar',
        loadChildren: () => import('app/modules/dashboards/calendar/calendar.module').then((m) => m.CalendarModule),
      },
      {
        path: 'configuracoes',
        loadChildren: () => import('app/modules/settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'academy',
        loadChildren: () => import('app/modules/academy/academy.module').then((m) => m.AcademyModule),
      },
      {
        path: 'servicos',
        loadChildren: () => import('app/modules/servicos/servicos.module').then((m) => m.ServicosModule),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule),
      },
      {
        path: 'unlock-session',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then((m) => m.AuthUnlockSessionModule),
      },
    ],
  },

  //
  // ======================= DEMAIS ROTAS =======================================
  //

  {
    component: LayoutComponent,
    path: '',
    children: [
      {
        path: 'tv',
        loadChildren: () => import('app/modules/tv/tv.module').then((m) => m.TvModule),
      },
      {
        path: 'sessao',
        loadChildren: () => import('app/modules/votacao/votacao.module').then((m) => m.VotacaoModule),
      },
    ],
    data: {
      layout: 'empty',
    },
  },
  {
    component: LayoutComponent,
    path: '',
    children: [
      {
        path: 'extra',
        loadChildren: () => import('app/modules/extra/extra.module').then((m) => m.ExtraModule),
      },
    ],
    data: {
      layout: 'empty',
    },
  },
  {
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    path: '',
    children: [
      {
        path: 'tv-guard',
        loadChildren: () => import('app/modules/tv-guard/tv-guard.module').then((m) => m.TvGuardModule),
      },
    ],
    data: {
      layout: 'empty',
    },
  },
];
