import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatButtonModule } from '@angular/material/button';
import { PortalModule } from '@angular/cdk/portal';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

import { NotifyComponent } from './notify.component';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  declarations: [NotifyComponent],
  imports: [RouterModule, OverlayModule, PortalModule, MatButtonModule, MatIconModule, MatTooltipModule, SharedModule],
  exports: [NotifyComponent],
})
export class NotifyModule {}
