import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { NotifyService } from './notify.service';
import { Notificacao } from 'app/model/notificacao';

@Injectable({
  providedIn: 'root',
})
export class MinhasNotificacoesResolver implements Resolve<Notificacao[]> {
  /**
   * Constructor
   */
  constructor(private readonly _notifyService: NotifyService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Notificacao[]> {
    return this._notifyService.obterMinhasNotificacoes();
  }
}
