import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { differenceInCalendarDays } from 'date-fns';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';

import { Notificacao } from 'app/model/notificacao';
import { NotifyService } from './notify.service';
import { WebSocketService } from 'app/shared/services/WebSocket.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss'],
})
export class NotifyComponent implements OnInit, OnDestroy {
  @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
  @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

  public contadorNotificacoes: number = 0;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public minhasNotificacoes: Notificacao[] = [];
  /**
   * Constructor
   */
  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _notifyService: NotifyService,
    private readonly _overlay: Overlay,
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _webSocketService: WebSocketService,
    private readonly _authService: AuthService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Métodos de ciclo de vida dos componentes
  // -----------------------------------------------------------------------------------------------------
  ngOnInit(): void {
    this._notifyService.minhasNotificacoes$.pipe(takeUntil(this._unsubscribeAll)).subscribe((minhasNotificacoes: Notificacao[]) => {
      if (minhasNotificacoes) {
        this.minhasNotificacoes = minhasNotificacoes;
        this.contadorNotificacoes = this.minhasNotificacoes.length;
        this._changeDetectorRef.detectChanges();
      }

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });

    this._authService.userValue$.pipe(takeUntil(this._unsubscribeAll)).subscribe((usuarioLogado) => {
      let url = environment.api;
      url = url.replace('/rest', '/socket');

      this._webSocketService.connect(url, 'nova_notificacao_' + usuarioLogado.login, 'stompNotification').subscribe((message: any) => {
        if (message.body) {
          try {
            const notificacao = JSON.parse(message.body);
            // Adiciona a nova notificação ao início da lista
            this.minhasNotificacoes.unshift(notificacao);
            // Atualiza o contador de notificações
            this.contadorNotificacoes = this.minhasNotificacoes.length;
            // Atualiza a interface
            this._changeDetectorRef.detectChanges();
          } catch (e) {
            console.error('Erro ao processar a notificação:', e);
          }
        } else {
          console.error('Mensagem recebida com body vazio.');
        }
      });
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();

    // Dispose the overlay
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }
  // -------------------------------------------------------------------
  // @ Métodos públicos
  // -------------------------------------------------------------------
  openPanel(): void {
    // Return if the notifications panel or its origin is not defined
    if (!this._notificationsPanel || !this._notificationsOrigin) {
      return;
    }

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
  }

  /**
   * Close the notifications panel
   */
  closePanel(): void {
    this._overlayRef.detach();
  }

  calcularDias(dataCriacao: string | Date): string {
    const dataCriada = new Date(dataCriacao);
    const hoje = new Date();
    const diferencaDias = differenceInCalendarDays(hoje, dataCriada);

    if (diferencaDias === 0) {
      return 'hoje';
    } else if (diferencaDias === 1) {
      return '1 dia';
    } else {
      return `${diferencaDias} dias`;
    }
  }

  marcarComoLida(notificacao: Notificacao): void {
    notificacao.lido = true;
    this._notifyService.marcarComoLida(notificacao).subscribe({
      next: () => {
        const index = this.minhasNotificacoes.findIndex(n => n.id === notificacao.id)
        if (index !== -1) {
          this.minhasNotificacoes.splice(index, 1)
          this.contadorNotificacoes--;
          this._changeDetectorRef.detectChanges();
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error(error.error.message);
        this._changeDetectorRef.detectChanges();
      },
    });
  }

  marcarTodasComoLidas(): void {
    this.minhasNotificacoes.forEach((notificacao: Notificacao) => {
      notificacao.lido = true;
    });
    this._notifyService.marcarTodasComoLida().subscribe({
      next: () => {
        this.minhasNotificacoes = [];
        this.contadorNotificacoes = 0;
        this._changeDetectorRef.detectChanges();
      },
      error: (error: HttpErrorResponse) => {
        console.error(error.error.message);
        this._changeDetectorRef.detectChanges();
      },
    });
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    if (!item) {
      return index;
    }
    return item.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Create the overlay
   */ private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }
}
