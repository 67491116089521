<!-- Notifications toggle -->
<button mat-icon-button (click)="openPanel()" #notificationsOrigin>
  <ng-container *ngIf="contadorNotificacoes > 0">
    <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
      <span
        class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-teal-600 text-indigo-50 text-xs font-medium"
      >
        {{ contadorNotificacoes }}
      </span>
    </span>
  </ng-container>
  <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>
  <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 fixed-panel overflow-hidden shadow-lg">
    <!-- Header -->
    <div class="flex shrink-0 items-center justify-between py-4 pr-4 pl-6 bg-primary text-on-primary">
      <div class="text-lg font-medium leading-10">Notificações</div>
      <button mat-icon-button [matTooltip]="'Marcar todas como lidas'" (click)="marcarTodasComoLidas()" class="text-white">
        <mat-icon [svgIcon]="'heroicons_outline:check'" class="text-white"></mat-icon>
      </button>
    </div>

    <!-- Content -->
    <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
      <!-- Notifications -->
      <ng-container *ngFor="let notificacao of minhasNotificacoes; trackBy: trackByFn">
        <div
          class="flex items-center group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5 notification-item cursor-pointer"
          (click)="marcarComoLida(notificacao)"
          [ngClass]="{ 'bg-gray-100': !notificacao.lido, 'bg-white': notificacao.lido }"
        >
          <!-- Imagem -->
          <div class="relative flex justify-center items-center min-w-12 min-h-12 mr-4">
            <img class="w-8 h-8 rounded-full" [src]="notificacao.caminhoImagem || 'assets/icons/default-avatar.png'" alt="Notificação" />
            <span *ngIf="!notificacao.lido" class="absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full"></span>
          </div>

          <!-- Conteúdo -->
          <div class="flex flex-col flex-auto notification-content">
            <div class="font-semibold">{{ notificacao.descricao }}</div>
            <div class="text-sm text-secondary">
              Para: <span class="font-medium">{{ notificacao.usuario?.pessoa?.nome }}</span>
            </div>
            <div class="mt-1 text-xs leading-none text-secondary">
              {{ notificacao.criadoEm | date : 'dd/MM/yyyy, HH:mm' }}
              ({{ calcularDias(notificacao.criadoEm) }})
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Sem notificações -->
      <ng-container *ngIf="!minhasNotificacoes || !minhasNotificacoes.length">
        <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
          <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
            <mat-icon class="text-primary-700 dark:text-primary-50" [svgIcon]="'heroicons_outline:bell'"></mat-icon>
          </div>
          <div class="mt-5 text-2xl font-semibold tracking-tight">Nenhuma notificação</div>
          <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">Quando você receber uma notificação, ela aparecerá aqui.</div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
